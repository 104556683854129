var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.resident)?_c('div',{staticClass:"showRes"},[_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":_vm.resident.image_url,"alt":""}}),_c('VerticalShow')],1),_c('section',{staticClass:"main"},[_vm._m(0),_c('div',{staticClass:"info"},[_c('h2',{staticClass:"res-name"},[_vm._v(_vm._s(_vm.resident.name))]),(
                              _vm.resident.soundcloud_url ||
                              _vm.resident.mixcloud_url ||
                              _vm.resident.facebook_url ||
                              _vm.resident.twitter_url ||
                              _vm.resident.instagram_url ||
                              _vm.resident.bandcamp_url ||
                              _vm.resident.youtube_url)?_c('div',{staticClass:"links"},[(_vm.resident.soundcloud_url)?_c('div',{staticClass:"link"},[_c('Icon',{attrs:{"linkto":_vm.resident.soundcloud_url,"iconClass":'fa fa-soundcloud'}})],1):_vm._e(),(_vm.resident.mixcloud_url)?_c('div',{staticClass:"link"},[_c('Icon',{attrs:{"linkto":_vm.resident.mixcloud_url,"iconClass":'fa fa-mixcloud'}})],1):_vm._e(),(_vm.resident.facebook_url)?_c('div',{staticClass:"link"},[_c('Icon',{attrs:{"linkto":_vm.resident.facebook_url,"iconClass":'fab fa-facebook'}})],1):_vm._e(),(_vm.resident.twitter_url)?_c('div',{staticClass:"link"},[_c('Icon',{attrs:{"linkto":_vm.resident.twitter_url,"iconClass":'fa fa-twitter'}})],1):_vm._e(),(_vm.resident.instagram_url)?_c('div',{staticClass:"link"},[_c('Icon',{attrs:{"linkto":_vm.resident.instagram_url,"iconClass":'fa fa-instagram'}})],1):_vm._e(),(_vm.resident.bandcamp_url)?_c('div',{staticClass:"link"},[_c('Icon',{attrs:{"linkto":_vm.resident.bandcamp_url,"iconClass":'fa fa-bandcamp'}})],1):_vm._e(),(_vm.resident.youtube_url)?_c('div',{staticClass:"link"},[_c('Icon',{attrs:{"linkto":_vm.resident.youtube_url,"iconClass":'fab fa-youtube'}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"description"},[_c('vue-markdown',[_vm._v(" "+_vm._s(_vm.resident.description)+" ")])],1),_c('div',{staticClass:"cards"},_vm._l((_vm.shows),function(show){return _c('ShowCard',{key:show.key,staticClass:"grid-item show",attrs:{"name":show.name,"image":show.pictures.large,"type":'show',"id":show.key,"tags":show.tags,"small":true}})}),1)])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-pane"},[_c('p',{staticClass:"header"},[_vm._v("resident")])])}]

export { render, staticRenderFns }